import { storage } from '../firebase/config';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth } from '../firebase/config';

export const uploadImage = async (file: File, path: string, userId?: string): Promise<string> => {
  if (!auth.currentUser) {
    throw new Error('Authentication required to upload images');
  }

  const targetUserId = userId || auth.currentUser.uid;
  const storageRef = ref(storage, `users/${targetUserId}/${path}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

export const deleteImage = async (url: string, userId?: string): Promise<void> => {
  if (!auth.currentUser) {
    throw new Error('Authentication required to delete images');
  }

  try {
    // Extract the path from the URL
    const decodedUrl = decodeURIComponent(url);
    const pathMatch = decodedUrl.match(/o\/(.+?)\?/);
    if (!pathMatch) {
      throw new Error('Invalid storage URL');
    }

    // The path will be something like "users/userId/path/to/file"
    const fullPath = pathMatch[1].replace(/%2F/g, '/');
    
    // Create a reference directly to the full path
    const storageRef = ref(storage, fullPath);
    
    await deleteObject(storageRef);
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error; // Re-throw the error to handle it in the component
  }
};

export const getImageUrl = async (userId: string, path: string): Promise<string> => {
  if (!auth.currentUser) {
    throw new Error('Authentication required to access images');
  }

  const storageRef = ref(storage, `users/${userId}/${path}`);
  try {
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.error('Error getting image URL:', error);
    throw error;
  }
};

// Helper function to get current user's image URL (for backward compatibility)
export const getCurrentUserImageUrl = async (path: string): Promise<string> => {
  if (!auth.currentUser) {
    throw new Error('Authentication required to access images');
  }
  return getImageUrl(auth.currentUser.uid, path);
};
