import { FC, ReactNode } from 'react';
import { Box, Container, Paper } from '@mui/material';

interface ProposalsLayoutProps {
  children: ReactNode;
}

const ProposalsLayout: FC<ProposalsLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ width: '100%' }}>
          {children}
        </Box>
      </Paper>
    </Container>
  );
};

export default ProposalsLayout;
