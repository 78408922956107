import React from 'react';
import { Typography, useTheme, useMediaQuery } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface FormErrorProps {
  message: string;
}

const FormError: React.FC<FormErrorProps> = ({ message }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!message) return null;

  return (
    <Typography
      variant="caption"
      color="error"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        mt: 0.5,
        fontSize: isMobile ? '0.7rem' : '0.75rem',
        lineHeight: 1.2
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }} />
      {message}
    </Typography>
  );
};

export default FormError;
