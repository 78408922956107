import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  onAuthStateChanged,
  signOut,
  User,
  updateProfile,
  updateEmail,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { ensureUserDocument } from '../services/firestore';
import * as authService from '../services/auth';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  initialized: boolean; // Add initialized state
  login: (email: string, password: string) => Promise<User>;
  signup: (email: string, password: string, displayName: string) => Promise<User>;
  resetPassword: (email: string) => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  updateUserEmail: (email: string) => Promise<void>;
  updateUserPassword: (password: string) => Promise<void>;
  logout: () => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  loading: true,
  initialized: false,
  login: async () => { throw new Error('Not implemented'); },
  signup: async () => { throw new Error('Not implemented'); },
  resetPassword: async () => { throw new Error('Not implemented'); },
  updateDisplayName: async () => { throw new Error('Not implemented'); },
  updateUserEmail: async () => { throw new Error('Not implemented'); },
  updateUserPassword: async () => { throw new Error('Not implemented'); },
  logout: async () => { throw new Error('Not implemented'); },
  resendVerificationEmail: async () => { throw new Error('Not implemented'); },
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.uid);
      try {
        if (user) {
          // Always attempt to ensure user document exists on auth state change
          const success = await ensureUserDocument(user.uid, user.email || '', user.displayName || '');
          if (!success) {
            console.error('Failed to ensure user document exists');
            setCurrentUser(null);
          } else {
            setCurrentUser(user);
          }
        } else {
          setCurrentUser(null);
        }
      } catch (error) {
        console.error('Error in auth state change:', error);
        setCurrentUser(null);
      } finally {
        setLoading(false);
        setInitialized(true);
      }
    });

    return unsubscribe;
  }, []);

  const login = async (email: string, password: string) => {
    setLoading(true);
    try {
      const user = await authService.login(email, password);
      return user;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (email: string, password: string, displayName: string) => {
    setLoading(true);
    try {
      const user = await authService.signup(email, password, displayName);
      return user;
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = (email: string) => {
    return authService.resetPassword(email);
  };

  const updateDisplayName = async (displayName: string) => {
    if (!currentUser) {
      throw new Error('No user logged in');
    }

    setLoading(true);
    try {
      await updateProfile(currentUser, { displayName });
      const success = await ensureUserDocument(currentUser.uid, currentUser.email || '', displayName);
      if (!success) {
        throw new Error('Failed to update profile');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUserEmail = async (email: string) => {
    if (!currentUser) {
      throw new Error('No user logged in');
    }

    setLoading(true);
    try {
      await updateEmail(currentUser, email);
      const success = await ensureUserDocument(currentUser.uid, email, currentUser.displayName || '');
      if (!success) {
        throw new Error('Failed to update email');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateUserPassword = async (password: string) => {
    if (!currentUser) {
      throw new Error('No user logged in');
    }

    setLoading(true);
    try {
      await updatePassword(currentUser, password);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
    } finally {
      setLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    if (!currentUser) {
      throw new Error('No user logged in');
    }
    throw new Error('Not implemented');
  };

  const value = {
    currentUser,
    loading,
    initialized,
    login,
    signup,
    resetPassword,
    updateDisplayName,
    updateUserEmail,
    updateUserPassword,
    logout,
    resendVerificationEmail,
  };

  // Don't render children until auth is initialized
  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
