import { FC } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const ProposalsHeader: FC = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
      <Typography variant="h5" component="h2">
        Proposals
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        component={RouterLink}
        to="/new-proposal"
      >
        New Proposal
      </Button>
    </Box>
  );
};

export default ProposalsHeader;
