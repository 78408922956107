import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  Box, 
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment
} from '@mui/material';
import { collection, doc, getDoc, setDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import FormTextField from '../common/FormTextField';
import FormSelect from '../common/FormSelect';
import { Invoice, InvoiceItem, InvoiceDescription } from './types';
import { formatCurrency, parseCurrencyInput } from '../../utils/numberFormat';

const defaultInvoiceItem: InvoiceItem = {
  description: 'Level 1 Reserve Study',
  amount: ''
};

const defaultFormData: Omit<Invoice, 'id' | 'createdAt'> = {
  clientName: '',
  clientAddressLine2: '',
  clientStreetAddress: '',
  clientCity: '',
  clientState: '',
  clientZip: '',
  invoiceNumber: '',
  date: new Date().toISOString().split('T')[0],
  dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
  amount: '0',
  items: [{ ...defaultInvoiceItem }]
};

const descriptionOptions: InvoiceDescription[] = [
  "Level 1 Reserve Study",
  "Level 2 Reserve Study",
  "Level 3 Reserve Study",
  "1/3 of the 3 Year Reserve Study Contract",
  "Building Envelope Inspection",
  "Playground Safety Inspection"
];

const InvoiceForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = React.useState(defaultFormData);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isBlueCommunity, setIsBlueCommunity] = React.useState(false);

  React.useEffect(() => {
    const loadInvoice = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const docRef = doc(db, 'invoices', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const invoiceData = docSnap.data() as Omit<Invoice, 'id'>;
            setFormData(invoiceData);
          }
        } catch (error) {
          console.error('Error loading invoice:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadInvoice();
  }, [id]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlueCommunityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsBlueCommunity(checked);
    
    if (checked) {
      setFormData(prev => ({
        ...prev,
        clientAddressLine2: 'c/o Blue Mountain Community',
        clientStreetAddress: '17933 NW Evergreen Place, Suite 200',
        clientCity: 'Beaverton',
        clientState: 'OR',
        clientZip: '97006'
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        clientAddressLine2: '',
        clientStreetAddress: '',
        clientCity: '',
        clientState: '',
        clientZip: ''
      }));
    }
  };

  const handleItemChange = (field: keyof InvoiceItem, value: string) => {
    let processedValue = value;
    if (field === 'amount') {
      processedValue = parseCurrencyInput(value);
    }
    
    const newItem = { ...formData.items[0], [field]: processedValue };
    
    setFormData(prev => ({
      ...prev,
      items: [newItem],
      amount: field === 'amount' ? processedValue : prev.amount
    }));
  };

  const validateForm = () => {
    return (
      formData.clientName && 
      formData.clientStreetAddress &&
      formData.clientCity &&
      formData.clientState &&
      formData.clientZip &&
      formData.invoiceNumber && 
      formData.date && 
      formData.dueDate &&
      formData.items[0].description &&
      parseFloat(formData.items[0].amount) > 0
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      if (id) {
        const docRef = doc(db, 'invoices', id);
        await updateDoc(docRef, {
          ...formData,
          updatedAt: serverTimestamp()
        });
      } else {
        const invoicesRef = collection(db, 'invoices');
        const newDocRef = doc(invoicesRef);
        await setDoc(newDocRef, {
          ...formData,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp()
        });
      }
      navigate('/invoices');
    } catch (error) {
      console.error('Error saving invoice:', error);
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography>Loading...</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {id ? 'Edit Invoice' : 'New Invoice'}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={8}>
                <FormTextField
                  label="Client Name"
                  name="clientName"
                  value={formData.clientName}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBlueCommunity}
                      onChange={handleBlueCommunityChange}
                      color="primary"
                    />
                  }
                  label="BLUE Community"
                />
              </Grid>
            </Grid>
            
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <FormTextField
                  label="C/O or Additional Info"
                  name="clientAddressLine2"
                  value={formData.clientAddressLine2}
                  onChange={handleTextChange}
                  fullWidth
                  placeholder="C/O Property Manager Name"
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  label="Street Address"
                  name="clientStreetAddress"
                  value={formData.clientStreetAddress}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  label="City"
                  name="clientCity"
                  value={formData.clientCity}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormTextField
                  label="State"
                  name="clientState"
                  value={formData.clientState}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormTextField
                  label="ZIP Code"
                  name="clientZip"
                  value={formData.clientZip}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>

            <FormTextField
              label="Invoice Number"
              name="invoiceNumber"
              value={formData.invoiceNumber}
              onChange={handleTextChange}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  label="Invoice Date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  label="Due Date"
                  name="dueDate"
                  type="date"
                  value={formData.dueDate}
                  onChange={handleTextChange}
                  required
                  fullWidth
                />
              </Grid>
            </Grid>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormSelect
                        label="Service Type"
                        value={formData.items[0].description}
                        onChange={(e) => handleItemChange('description', e.target.value)}
                        fullWidth
                      >
                        {descriptionOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </FormSelect>
                    </TableCell>
                    <TableCell align="right">
                      <FormTextField
                        value={formData.items[0].amount}
                        onChange={(e) => handleItemChange('amount', e.target.value)}
                        required
                        sx={{ width: '150px' }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="h6">
                Total: ${formatCurrency(formData.amount)}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!validateForm()}
            >
              {id ? 'Update Invoice' : 'Create Invoice'}
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default InvoiceForm;
