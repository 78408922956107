import React from 'react';
import { Container, Paper } from '@mui/material';

interface InvoicesLayoutProps {
  children: React.ReactNode;
}

const InvoicesLayout: React.FC<InvoicesLayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        {children}
      </Paper>
    </Container>
  );
};

export default InvoicesLayout;
