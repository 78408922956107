import { Grid, Typography } from '@mui/material';
import FormTextField from '../common/FormTextField';
import type { CompanyInfo } from './types';

interface CompanyInfoFormProps {
  companyInfo: CompanyInfo;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CompanyInfoForm = ({ companyInfo, onChange }: CompanyInfoFormProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Company Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            label="Company Name"
            name="companyName"
            value={companyInfo.companyName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Address"
            name="address"
            value={companyInfo.address}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="City"
            name="city"
            value={companyInfo.city}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            label="State"
            name="state"
            value={companyInfo.state}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormTextField
            label="ZIP Code"
            name="zipCode"
            value={companyInfo.zipCode}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Email"
            name="email"
            type="email"
            value={companyInfo.email}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Phone"
            name="phone"
            value={companyInfo.phone}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Website"
            name="website"
            value={companyInfo.website}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyInfoForm;
