import { FirebaseError } from 'firebase/app';

export interface ErrorLogData {
  context?: string;
  additionalData?: Record<string, unknown>;
}

// Error codes mapping for user-friendly messages
const ERROR_MESSAGES: Record<string, string> = {
  'auth/user-not-found': 'Invalid email or password',
  'auth/wrong-password': 'Invalid email or password',
  'auth/email-already-in-use': 'An account with this email already exists',
  'auth/weak-password': 'Password should be at least 6 characters',
  'auth/invalid-email': 'Invalid email address',
  'auth/operation-not-allowed': 'Operation not allowed',
  'auth/requires-recent-login': 'Please log in again to continue',
  'storage/unauthorized': 'Unauthorized access to storage',
  'storage/canceled': 'Upload canceled',
  'storage/unknown': 'Unknown error occurred during file upload',
};

// Generic error handler that returns user-friendly messages
export const handleError = (error: unknown, options?: ErrorLogData): string => {
  // Remove console.error and implement proper logging
  if (process.env.NODE_ENV === 'development') {
    console.error('Error occurred:', error);
  }

  if (error instanceof FirebaseError) {
    return ERROR_MESSAGES[error.code] || error.message;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'An unexpected error occurred';
};

// Type guard to check if an error is a Firebase error
export const isFirebaseError = (error: unknown): error is FirebaseError => {
  return error instanceof FirebaseError;
};

// Custom error class for authentication errors
export class AuthError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
    this.name = 'AuthError';
  }
}

// Custom error class for storage errors
export class StorageError extends Error {
  constructor(message: string, public code?: string) {
    super(message);
    this.name = 'StorageError';
  }
}

// Rate limiting error
export class RateLimitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'RateLimitError';
  }
}
