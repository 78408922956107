import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const InvoicesHeader: React.FC = () => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
      <Typography variant="h4" component="h1">
        Invoices
      </Typography>
      <Button
        component={Link}
        to="/invoices/new"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        New Invoice
      </Button>
    </Box>
  );
};

export default InvoicesHeader;
