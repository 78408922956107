import React from 'react';
import { Font } from '@react-pdf/renderer';
import FormEnabledPDF from '../../services/pdf/components/FormEnabledPDF';
import { Proposal } from './types';

// Initialize Font configuration
Font.register({
  family: 'Roboto',
  src: '/fonts/Roboto-Regular.ttf'
});

interface Props {
  proposal: Proposal & {
    levelOneSelected?: boolean;
    levelTwoSelected?: boolean;
    levelThreeSelected?: boolean;
    levelFourSelected?: boolean;
    threeYear133Selected?: boolean;
    threeYear233Selected?: boolean;
  };
  backgroundImages?: string[];
}

const ProposalPreview: React.FC<Props> = ({ proposal, backgroundImages = [] }) => {
  return (
    <FormEnabledPDF 
      proposal={proposal} 
      backgroundImages={backgroundImages}
    />
  );
};

export default ProposalPreview;
