import { Snackbar, Alert } from '@mui/material';
import { SnackbarState } from './hooks/useSettingsSnackbar';

interface SettingsSnackbarProps {
  snackbar: SnackbarState;
  onClose: () => void;
}

export const SettingsSnackbar = ({ snackbar, onClose }: SettingsSnackbarProps) => (
  <Snackbar
    open={snackbar.open}
    autoHideDuration={6000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert
      onClose={onClose}
      severity={snackbar.severity}
      sx={{ width: '100%' }}
    >
      {snackbar.message}
    </Alert>
  </Snackbar>
);
