import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Invoice } from './types';
import InvoiceDocument from '../../services/pdf/components/InvoiceDocument';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { fetchSettings } from '../../services/firestore';
import { Settings } from '../../services/types';

interface InvoicePreviewProps {
  invoice: Invoice;
}

const InvoicePreview: React.FC<InvoicePreviewProps> = ({ invoice }) => {
  const [settings, setSettings] = useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settingsData = await fetchSettings();
        setSettings(settingsData);
      } catch (error) {
        console.error('Error loading settings:', error);
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const invoiceBase = settings?.company?.invoiceBase || undefined;

  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <InvoiceDocument
        invoice={invoice}
        invoiceBase={invoiceBase}
      />
    </PDFViewer>
  );
};

export default InvoicePreview;
