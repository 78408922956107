import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { Proposal } from '../../../components/proposals/types';
import ProposalDocument from '../components/ProposalDocument';
import { addSignatureField } from './formFieldUtils';

interface ProposalWithSelections extends Proposal {
  levelOneSelected?: boolean;
  levelTwoSelected?: boolean;
  levelThreeSelected?: boolean;
  levelFourSelected?: boolean;
  threeYear133Selected?: boolean;
  threeYear233Selected?: boolean;
}

// Cache for storing fetched image data with timestamp
interface CacheEntry {
  data: string;
  timestamp: number;
}

const imageCache = new Map<string, CacheEntry>();
const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

// Clean expired cache entries
const cleanCache = () => {
  const now = Date.now();
  // Convert to array for compatibility with older JS versions
  const urls = Array.from(imageCache.keys());
  urls.forEach(url => {
    const entry = imageCache.get(url);
    if (entry && now - entry.timestamp > CACHE_EXPIRY) {
      imageCache.delete(url);
    }
  });
};

// Function to fetch and cache image data
const fetchAndCacheImage = async (url: string): Promise<string> => {
  if (!url) return '';
  
  // Clean expired cache entries
  cleanCache();

  // Return cached data if available and not expired
  const cached = imageCache.get(url);
  if (cached && Date.now() - cached.timestamp < CACHE_EXPIRY) {
    return cached.data;
  }

  try {
    // Fetch the image
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();
    
    // Convert to base64
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result as string;
        // Cache the result with timestamp
        imageCache.set(url, {
          data: base64data,
          timestamp: Date.now()
        });
        resolve(base64data);
      };
      reader.onerror = () => {
        reject(new Error('Failed to convert image to base64'));
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error processing image:', error);
    // Remove failed URL from cache if it exists
    imageCache.delete(url);
    return '';
  }
};

export const generateProposalPdfBlob = async (
  proposal: ProposalWithSelections,
  backgroundImages?: string[]
): Promise<Blob> => {
  try {
    // Convert URLs to base64 data for PDF generation
    const processedBackgroundImages = await Promise.all(
      (backgroundImages || []).map(url => fetchAndCacheImage(url))
    );

    // Filter out any failed image loads
    const validImages = processedBackgroundImages.filter(Boolean);

    // Generate initial PDF
    const initialPdfBlob = await pdf(
      React.createElement(ProposalDocument, { 
        proposal,
        backgroundImages: validImages
      })
    ).toBlob();

    try {
      // Convert Blob to Uint8Array
      const pdfBytes = new Uint8Array(await initialPdfBlob.arrayBuffer());
      
      // Add signature field
      const modifiedPdfBytes = await addSignatureField(pdfBytes);
      
      // Convert back to Blob
      return new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    } catch (error) {
      console.error('Error adding form fields:', error);
      // If form field addition fails, return the original PDF
      return initialPdfBlob;
    }
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw new Error('Failed to generate PDF document');
  }
};

export const downloadProposalPdf = async (blob: Blob, fileName: string): Promise<void> => {
  let objectUrl: string | null = null;
  try {
    objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = `proposal-${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading PDF:', error);
    throw new Error('Failed to download PDF');
  } finally {
    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
  }
};

// Export cache cleanup function for manual cache management if needed
export const clearImageCache = () => {
  imageCache.clear();
};
