import React from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

interface InvoiceBaseUploadProps {
  invoiceBase: string | null;
  onInvoiceBaseUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInvoiceBaseDelete: () => void;
}

const InvoiceBaseUpload = ({ 
  invoiceBase, 
  onInvoiceBaseUpload, 
  onInvoiceBaseDelete 
}: InvoiceBaseUploadProps) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Invoice Background Image
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Upload a background image for your invoices
      </Typography>
      
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            border: '1px solid #ddd',
            borderRadius: 1,
            p: 2,
          }}
        >
          {invoiceBase ? (
            <Box sx={{ position: 'relative', mb: 2 }}>
              <img
                src={invoiceBase}
                alt="Invoice background"
                style={{
                  width: '100%',
                  height: '120px',
                  objectFit: 'cover',
                  borderRadius: '4px',
                }}
              />
              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 4,
                  right: 4,
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'background.paper' },
                }}
                onClick={onInvoiceBaseDelete}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : (
            <Button
              variant="outlined"
              component="label"
              fullWidth
              startIcon={<CloudUploadIcon />}
              sx={{ height: '120px' }}
            >
              Upload Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={onInvoiceBaseUpload}
              />
            </Button>
          )}
        </Box>
      </Box>
      
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        Recommended: Use images with dimensions matching A4 paper (2480 x 3508 pixels) for best results
      </Typography>
    </Box>
  );
};

export default InvoiceBaseUpload;
