import { Box, Divider } from '@mui/material';
import BasicLogoUpload from './BasicLogoUpload';
import CoverBackgroundUpload from './CoverBackgroundUpload';
import ProposalBaseUpload from './ProposalBaseUpload';
import InvoiceBaseUpload from './InvoiceBaseUpload';

interface BrandingSectionProps {
  basicLogo: string | null;
  coverBackground: string | null;
  proposalBase: string[];
  invoiceBase: string | null;
  onBasicLogoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCoverBackgroundUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onProposalBaseUpload: (pageIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onProposalBaseDelete: (pageIndex: number) => void;
  onInvoiceBaseUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInvoiceBaseDelete: () => void;
}

const BrandingSection = ({
  basicLogo,
  coverBackground,
  proposalBase,
  invoiceBase,
  onBasicLogoUpload,
  onCoverBackgroundUpload,
  onProposalBaseUpload,
  onProposalBaseDelete,
  onInvoiceBaseUpload,
  onInvoiceBaseDelete
}: BrandingSectionProps) => {
  return (
    <Box>
      <BasicLogoUpload basicLogo={basicLogo} onBasicLogoUpload={onBasicLogoUpload} />
      <Divider sx={{ my: 2 }} />
      <CoverBackgroundUpload
        coverBackground={coverBackground}
        onCoverBackgroundUpload={onCoverBackgroundUpload}
      />
      <Divider sx={{ my: 2 }} />
      <ProposalBaseUpload
        proposalBase={proposalBase}
        onProposalBaseUpload={onProposalBaseUpload}
        onProposalBaseDelete={onProposalBaseDelete}
      />
      <Divider sx={{ my: 2 }} />
      <InvoiceBaseUpload
        invoiceBase={invoiceBase}
        onInvoiceBaseUpload={onInvoiceBaseUpload}
        onInvoiceBaseDelete={onInvoiceBaseDelete}
      />
    </Box>
  );
};

export default BrandingSection;
