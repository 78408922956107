import React from 'react';
import { Box, Typography, Button, Grid, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

interface ProposalBaseUploadProps {
  proposalBase: string[];
  onProposalBaseUpload: (pageIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  onProposalBaseDelete: (pageIndex: number) => void;
}

const ProposalBaseUpload = ({ proposalBase, onProposalBaseUpload, onProposalBaseDelete }: ProposalBaseUploadProps) => {
  const pages = ['First', 'Second', 'Third', 'Fourth'];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Proposal Background Images
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Upload background images for each page of your proposal
      </Typography>
      
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {pages.map((pageName, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box
              sx={{
                border: '1px solid #ddd',
                borderRadius: 1,
                p: 2,
                height: '100%',
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                {pageName} Page
              </Typography>
              
              {proposalBase[index] ? (
                <Box sx={{ position: 'relative', mb: 2 }}>
                  <img
                    src={proposalBase[index]}
                    alt={`Page ${index + 1} background`}
                    style={{
                      width: '100%',
                      height: '120px',
                      objectFit: 'cover',
                      borderRadius: '4px',
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: 'absolute',
                      top: 4,
                      right: 4,
                      bgcolor: 'background.paper',
                      '&:hover': { bgcolor: 'background.paper' },
                    }}
                    onClick={() => onProposalBaseDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  sx={{ height: '120px' }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={onProposalBaseUpload(index)}
                  />
                </Button>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        Recommended: Use images with dimensions matching A4 paper (2480 x 3508 pixels) for best results
      </Typography>
    </Box>
  );
};

export default ProposalBaseUpload;
