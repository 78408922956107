import { ReactNode } from 'react';
import { Typography, Paper, Container, Card, CardContent, Divider } from '@mui/material';

interface SettingsLayoutProps {
  children: ReactNode;
}

export const SettingsLayout = ({ children }: SettingsLayoutProps) => (
  <Container maxWidth="md">
    <Typography variant="h4" component="h1" gutterBottom>
      Settings
    </Typography>
    <Paper elevation={3} sx={{ p: 3 }}>
      {children}
    </Paper>
  </Container>
);

interface SettingsSectionProps {
  children: ReactNode;
  isLast?: boolean;
}

export const SettingsSection = ({ children, isLast = false }: SettingsSectionProps) => (
  <>
    <Card sx={{ mb: 2 }}>
      <CardContent>
        {children}
      </CardContent>
    </Card>
    {!isLast && <Divider sx={{ my: 2 }} />}
  </>
);
