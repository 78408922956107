import { FC, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  Container, 
  Paper, 
  Typography, 
  Box, 
  Button, 
  Stepper, 
  Step, 
  StepLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  SelectChangeEvent,
  MenuItem,
  Alert,
  InputAdornment
} from '@mui/material';
import { fetchSettings, createProposal, fetchProposal, updateProposal } from '../../services/firestore';
import FormTextField from '../common/FormTextField';
import FormSelect from '../common/FormSelect';
import { ProposalFormData } from './types';
import { formatCurrency, parseCurrencyInput } from '../../utils/numberFormat';

const steps = ['Basic Information', 'Price Levels'];

const regionOptions = [
  { value: 'wa_under_100', label: 'Washington with less than 100 doors' },
  { value: 'wa_over_100', label: 'Washington with more than 100 doors' },
  { value: 'or_under_100', label: 'Oregon with less than 100 doors' },
  { value: 'or_over_100', label: 'Oregon with more than 100 doors' },
];

const propertyTypeOptions = [
  { value: 'SFH', label: 'Single Family Home' },
  { value: 'TH', label: 'Townhouse' },
  { value: 'Condo', label: 'Condominium' },
];

const defaultFormData: ProposalFormData = {
  clientName: '',
  propertyAddress: '',
  date: new Date().toISOString().split('T')[0],
  region: '',
  propertyType: '',
  levelOneCost: '',
  levelTwoCost: '',
  levelThreeCost: '',
  levelFourCost: '',
  threeYear133Cost: '',
  threeYear233Cost: '',
  levelOneSelected: false,
  levelTwoSelected: false,
  levelThreeSelected: false,
  levelFourSelected: false,
  threeYear133Selected: false,
  threeYear233Selected: false
};

const ProposalForm: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<ProposalFormData>(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadProposal = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const proposal = await fetchProposal(id);
          if (proposal) {
            setFormData({
              clientName: proposal.clientName,
              propertyAddress: proposal.propertyAddress,
              date: proposal.date,
              region: proposal.region,
              propertyType: proposal.propertyType,
              levelOneCost: proposal.levelOneCost,
              levelTwoCost: proposal.levelTwoCost,
              levelThreeCost: proposal.levelThreeCost,
              levelFourCost: proposal.levelFourCost,
              threeYear133Cost: proposal.threeYear133Cost,
              threeYear233Cost: proposal.threeYear233Cost,
              levelOneSelected: proposal.levelOneSelected,
              levelTwoSelected: proposal.levelTwoSelected,
              levelThreeSelected: proposal.levelThreeSelected,
              levelFourSelected: proposal.levelFourSelected,
              threeYear133Selected: proposal.threeYear133Selected,
              threeYear233Selected: proposal.threeYear233Selected
            });
          }
        } catch (error) {
          console.error('Error loading proposal:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadProposal();
  }, [id]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checked = e.target.checked;
      
      // Count currently selected options
      const currentSelectedCount = [
        formData.levelOneSelected,
        formData.levelTwoSelected,
        formData.levelThreeSelected,
        formData.levelFourSelected,
        formData.threeYear133Selected,
        formData.threeYear233Selected
      ].filter(Boolean).length;

      // If unchecking, always allow it
      if (!checked) {
        setFormData(prev => ({
          ...prev,
          [name]: checked
        }));
        return;
      }

      // If checking and we don't have 3 selected yet, allow it
      if (checked && currentSelectedCount < 3) {
        setFormData(prev => ({
          ...prev,
          [name]: checked
        }));
      }
    } else if (name.includes('Cost')) {
      // Handle currency input
      const parsedValue = parseCurrencyInput(value);
      setFormData(prev => ({
        ...prev,
        [name]: parsedValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const fetchPriceLevels = async (region: string, propertyType: string) => {
    try {
      // Convert region format to match Firestore exactly
      const regionMap: { [key: string]: string } = {
        'wa_under_100': 'WA with <100 doors',
        'wa_over_100': 'WA with >100 doors',
        'or_under_100': 'OR with <100 doors',
        'or_over_100': 'OR with >100 doors'
      };

      // Convert property type to match Firestore exactly
      const propertyTypeMap: { [key: string]: string } = {
        'SFH': 'SFH',
        'TH': 'TH',
        'Condo': 'Condo'
      };

      const firestoreRegion = regionMap[region];
      const firestorePropertyType = propertyTypeMap[propertyType];

      if (!firestoreRegion || !firestorePropertyType) {
        console.error('Invalid region or property type mapping');
        return;
      }

      const settings = await fetchSettings();
      if (settings?.prices) {
        const priceData = settings.prices[firestoreRegion]?.[firestorePropertyType];
        
        if (priceData) {
          setFormData(prev => ({
            ...prev,
            levelOneCost: priceData['Level 1'] || '',
            levelTwoCost: priceData['Level 2'] || '',
            levelThreeCost: priceData['Level 3'] || '',
            levelFourCost: priceData['Level 4'] || '',
            threeYear133Cost: priceData['3 year (1+3+3)'] || '',
            threeYear233Cost: priceData['3 year (2+3+3)'] || ''
          }));
        } else {
          console.error('No price data found for the selected region and property type');
        }
      }
    } catch (error) {
      console.error('Error fetching price levels:', error);
    }
  };

  const handleSelectChange = (field: keyof ProposalFormData) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));

    // If both region and property type are selected, fetch price levels
    if (field === 'region' && formData.propertyType) {
      fetchPriceLevels(value, formData.propertyType);
    } else if (field === 'propertyType' && formData.region) {
      fetchPriceLevels(formData.region, value);
    }
  };

  const handleNext = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    setActiveStep((prevStep) => prevStep - 1);
  };

  const validateForm = () => {
    // Basic validation for required fields
    if (!formData.clientName || !formData.propertyAddress || !formData.date) {
      return false;
    }

    // Count selected options
    const selectedCount = [
      formData.levelOneSelected,
      formData.levelTwoSelected,
      formData.levelThreeSelected,
      formData.levelFourSelected,
      formData.threeYear133Selected,
      formData.threeYear233Selected
    ].filter(Boolean).length;

    // Must have exactly 3 options selected
    if (selectedCount !== 3) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      if (id) {
        await updateProposal(id, formData);
      } else {
        await createProposal(formData);
      }
      navigate('/proposals');
    } catch (error) {
      console.error('Error saving proposal:', error);
    }
  };

  const getSelectedCount = () => {
    return [
      formData.levelOneSelected,
      formData.levelTwoSelected,
      formData.levelThreeSelected,
      formData.levelFourSelected,
      formData.threeYear133Selected,
      formData.threeYear233Selected
    ].filter(Boolean).length;
  };

  const renderPriceLevelInput = (
    label: string,
    costField: keyof ProposalFormData,
    selectedField: keyof ProposalFormData
  ) => (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={8}>
        <FormTextField
          label={label}
          name={costField}
          value={formData[costField] as string}
          onChange={handleTextChange}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData[selectedField] as boolean}
              onChange={handleTextChange}
              name={selectedField}
              disabled={getSelectedCount() >= 3 && !formData[selectedField]}
            />
          }
          label="Include"
        />
      </Grid>
    </Grid>
  );

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <FormTextField
              label="Client Name"
              name="clientName"
              value={formData.clientName}
              onChange={handleTextChange}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormTextField
              label="Property Address"
              name="propertyAddress"
              value={formData.propertyAddress}
              onChange={handleTextChange}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormTextField
              label="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleTextChange}
              required
              fullWidth
            />
          </Box>
        );
      case 1:
        const selectedCount = getSelectedCount();
        const alertMessage = selectedCount === 3 
          ? "3 options selected (required)"
          : `Please select exactly 3 options (${selectedCount} selected)`;
        const alertSeverity = selectedCount === 3 ? "success" : "warning";
        
        return (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 3 }}>
              <FormSelect
                value={formData.region}
                onChange={handleSelectChange('region')}
                label="Region and Door Count"
                fullWidth
              >
                <MenuItem value="">
                  <em>Select a region</em>
                </MenuItem>
                {regionOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
            <Box sx={{ mb: 3 }}>
              <FormSelect
                value={formData.propertyType}
                onChange={handleSelectChange('propertyType')}
                label="Property Type"
                fullWidth
              >
                <MenuItem value="">
                  <em>Select a property type</em>
                </MenuItem>
                {propertyTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
            <Alert severity={alertSeverity} sx={{ mb: 2 }}>
              {alertMessage}
            </Alert>
            {renderPriceLevelInput('Level 1 Price', 'levelOneCost', 'levelOneSelected')}
            {renderPriceLevelInput('Level 2 Price', 'levelTwoCost', 'levelTwoSelected')}
            {renderPriceLevelInput('Level 3 Price', 'levelThreeCost', 'levelThreeSelected')}
            {renderPriceLevelInput('Level 4 Price', 'levelFourCost', 'levelFourSelected')}
            {renderPriceLevelInput('3 Year Contract (1+3+3)', 'threeYear133Cost', 'threeYear133Selected')}
            {renderPriceLevelInput('3 Year Contract (2+3+3)', 'threeYear233Cost', 'threeYear233Selected')}
          </Box>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography>Loading...</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {id ? 'Edit Proposal' : 'New Proposal'}
        </Typography>

        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <form onSubmit={handleSubmit}>
          {renderStepContent(activeStep)}

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
            )}
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!validateForm()}
              >
                {id ? 'Update Proposal' : 'Create Proposal'}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!formData.clientName || !formData.propertyAddress || !formData.date}
              >
                Next
              </Button>
            )}
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default ProposalForm;
