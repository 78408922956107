import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Proposal } from './types';

interface ProposalsTableProps {
  proposals: Proposal[];
  onPreview: (proposal: Proposal) => void;
  onGeneratePDF: (proposal: Proposal) => void;
  onDelete: (proposal: Proposal) => void;
  formatDate: (date: string) => string;
}

const ProposalsTable: React.FC<ProposalsTableProps> = ({
  proposals,
  onPreview,
  onGeneratePDF,
  onDelete,
  formatDate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ActionButtons = ({ proposal }: { proposal: Proposal }) => (
    <Stack 
      direction="row" 
      spacing={1} 
      sx={{ 
        flexWrap: isMobile ? 'wrap' : 'nowrap',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        onClick={() => onPreview(proposal)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Preview
      </Button>
      <Button
        variant="contained"
        size={isMobile ? "medium" : "small"}
        onClick={() => onGeneratePDF(proposal)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Download PDF
      </Button>
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        color="primary"
        href={`/proposal/${proposal.id}`}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Edit
      </Button>
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        color="error"
        onClick={() => onDelete(proposal)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Delete
      </Button>
    </Stack>
  );

  if (isMobile) {
    return (
      <Stack spacing={2} sx={{ mt: 2, px: 2 }}>
        {proposals.length === 0 ? (
          <Typography align="center" color="textSecondary">
            No proposals found
          </Typography>
        ) : (
          proposals.map((proposal) => (
            <Card key={proposal.id} elevation={2}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Client:</strong> {proposal.clientName}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Address:</strong> {proposal.propertyAddress}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Proposal Date:</strong> {formatDate(proposal.date)}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Property Type:</strong>{' '}
                  <span style={{ textTransform: 'capitalize' }}>{proposal.propertyType}</span>
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <ActionButtons proposal={proposal} />
                </Box>
              </CardContent>
            </Card>
          ))
        )}
      </Stack>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell>Property Address</TableCell>
            <TableCell>Proposal Date</TableCell>
            <TableCell>Property Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {proposals.length === 0 ? (
            <TableRow>
              <TableCell colSpan={5} align="center">
                No proposals found
              </TableCell>
            </TableRow>
          ) : (
            proposals.map((proposal) => (
              <TableRow key={proposal.id}>
                <TableCell>{proposal.clientName}</TableCell>
                <TableCell>{proposal.propertyAddress}</TableCell>
                <TableCell>{formatDate(proposal.date)}</TableCell>
                <TableCell style={{ textTransform: 'capitalize' }}>
                  {proposal.propertyType}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <ActionButtons proposal={proposal} />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProposalsTable;
