import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  updatePassword,
  User
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { AuthError, RateLimitError } from '../utils/errorHandling';
import { ensureUserDocument } from './firestore';

// Rate limiting configuration
const RATE_LIMIT_ATTEMPTS = 5;
const RATE_LIMIT_WINDOW = 15 * 60 * 1000; // 15 minutes

// Rate limiting state
let loginAttempts = 0;
let lastLoginAttempt = Date.now();

const checkRateLimit = () => {
  const now = Date.now();
  if (now - lastLoginAttempt > RATE_LIMIT_WINDOW) {
    // Reset if outside window
    loginAttempts = 0;
  }
  lastLoginAttempt = now;

  if (loginAttempts >= RATE_LIMIT_ATTEMPTS) {
    throw new RateLimitError('Too many login attempts. Please try again later.');
  }
  loginAttempts++;
};

export const signup = async (
  email: string,
  password: string,
  displayName: string
): Promise<User> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    // Update profile with display name
    await updateProfile(user, { displayName });
    
    // Ensure user document exists
    const success = await ensureUserDocument(user.uid, email, displayName);
    if (!success) {
      await user.delete();
      throw new AuthError('Failed to complete signup process');
    }

    return user;
  } catch (error: any) {
    if (error instanceof AuthError || error instanceof RateLimitError) {
      throw error;
    }
    throw new AuthError(error.message);
  }
};

export const login = async (email: string, password: string): Promise<User> => {
  try {
    checkRateLimit();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const { user } = userCredential;

    // Ensure user document exists/is updated
    const success = await ensureUserDocument(user.uid, email, user.displayName || undefined);
    if (!success) {
      throw new AuthError('Failed to update user information');
    }

    return user;
  } catch (error: any) {
    if (error instanceof AuthError || error instanceof RateLimitError) {
      throw error;
    }
    throw new AuthError(error.message);
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    throw new AuthError(error.message);
  }
};

export const updateUserProfile = async (user: User, displayName: string): Promise<void> => {
  try {
    await updateProfile(user, { displayName });
    await ensureUserDocument(user.uid, user.email!, displayName);
  } catch (error) {
    throw error;
  }
};

export const updateUserEmail = async (user: User, newEmail: string): Promise<void> => {
  try {
    await updateEmail(user, newEmail);
    await ensureUserDocument(user.uid, newEmail, user.displayName || undefined);
  } catch (error: any) {
    throw new AuthError(error.message);
  }
};

export const updateUserPassword = async (user: User, newPassword: string): Promise<void> => {
  try {
    await updatePassword(user, newPassword);
  } catch (error: any) {
    throw new AuthError(error.message);
  }
};
