import { useState, useEffect, ChangeEvent } from 'react';
import { fetchSettings, saveSettings } from '../../services/firestore';
import { uploadImage, deleteImage } from '../../services/storage';
import type { Settings, PriceSettings, CompanySettings, PriceLevelKey } from '../../services/types';
import { v4 as uuidv4 } from 'uuid';

const defaultPreferences = {
  includeCompanyLogo: true,
  includeCoverBackground: true,
  darkMode: false,
  compactView: false,
};

export const useSettingsForm = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Company Info
  const [companyInfo, setCompanyInfo] = useState<CompanySettings>({
    companyName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    website: '',
    phone: '',
    email: '',
  });

  // Personal Info
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: '',
  });

  // Preferences
  const [preferences, setPreferences] = useState(defaultPreferences);

  // Branding Assets
  const [basicLogo, setBasicLogo] = useState<string | null>(null);
  const [coverBackground, setCoverBackground] = useState<string | null>(null);
  const [proposalBase, setProposalBase] = useState<string[]>([]);
  const [invoiceBase, setInvoiceBase] = useState<string | null>(null);

  // Price Settings
  const [prices, setPrices] = useState<PriceSettings>({});

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await fetchSettings();
        if (settings) {
          setCompanyInfo({
            companyName: settings.company.companyName || '',
            address: settings.company.address || '',
            city: settings.company.city || '',
            state: settings.company.state || '',
            zipCode: settings.company.zipCode || '',
            website: settings.company.website || '',
            phone: settings.company.phone || '',
            email: settings.company.email || '',
          });

          setPersonalInfo({
            firstName: settings.personal.firstName || '',
            lastName: settings.personal.lastName || '',
            email: settings.personal.email || '',
            phone: settings.personal.phone || '',
            title: settings.personal.title || '',
          });

          setPreferences({
            ...defaultPreferences,
            ...settings.preferences,
          });

          setBasicLogo(settings.company.basicLogo || null);
          setCoverBackground(settings.company.coverBackground || null);
          setProposalBase(settings.company.proposalBase || []);
          setInvoiceBase(settings.company.invoiceBase || null);
          setPrices(settings.prices || {});
        }
      } catch (err) {
        setError('Failed to load settings');
        console.error('Error loading settings:', err);
      } finally {
        setLoading(false);
      }
    };

    loadSettings();
  }, []);

  const handleCompanyInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompanyInfo(prev => ({ ...prev, [name]: value }));
  };

  const handlePersonalInfoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPersonalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handlePreferencesChange = (name: string, value: boolean) => {
    setPreferences(prev => ({ ...prev, [name]: value }));
  };

  const handlePriceChange = (region: string, propertyType: string, level: PriceLevelKey, value: string) => {
    setPrices(prev => ({
      ...prev,
      [region]: {
        ...prev[region],
        [propertyType]: {
          ...prev[region]?.[propertyType],
          [level]: value,
        },
      },
    }));
  };

  const handleBasicLogoUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    const imageId = uuidv4();
    const url = await uploadImage(file, `branding/basicLogo/${imageId}_${file.name}`);
    setBasicLogo(url);
    await saveSettings({
      company: {
        ...companyInfo,
        basicLogo: url
      }
    });
    return url;
  };

  const handleCoverBackgroundUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    const imageId = uuidv4();
    const url = await uploadImage(file, `branding/coverBackground/${imageId}_${file.name}`);
    setCoverBackground(url);
    await saveSettings({
      company: {
        ...companyInfo,
        coverBackground: url
      }
    });
    return url;
  };

  const handleProposalBaseUpload = async (pageIndex: number) => async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    const imageId = uuidv4();
    const url = await uploadImage(file, `branding/proposalBase/${imageId}_${file.name}`);
    const updatedProposalBase = [...proposalBase];
    updatedProposalBase[pageIndex] = url;
    setProposalBase(updatedProposalBase);
    await saveSettings({
      company: {
        ...companyInfo,
        proposalBase: updatedProposalBase
      }
    });
    return url;
  };

  const handleInvoiceBaseUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const file = event.target.files[0];
    const imageId = uuidv4();
    const url = await uploadImage(file, `branding/invoiceBase/${imageId}_${file.name}`);
    setInvoiceBase(url);
    await saveSettings({
      company: {
        ...companyInfo,
        invoiceBase: url
      }
    });
    return url;
  };

  const handleBasicLogoDelete = async () => {
    if (basicLogo) {
      await deleteImage(basicLogo);
      setBasicLogo(null);
      await saveSettings({
        company: {
          ...companyInfo,
          basicLogo: null
        }
      });
    }
  };

  const handleCoverBackgroundDelete = async () => {
    if (coverBackground) {
      await deleteImage(coverBackground);
      setCoverBackground(null);
      await saveSettings({
        company: {
          ...companyInfo,
          coverBackground: null
        }
      });
    }
  };

  const handleProposalBaseDelete = async (pageIndex: number) => {
    const url = proposalBase[pageIndex];
    if (url) {
      await deleteImage(url);
      const updatedProposalBase = [...proposalBase];
      updatedProposalBase.splice(pageIndex, 1);
      setProposalBase(updatedProposalBase);
      await saveSettings({
        company: {
          ...companyInfo,
          proposalBase: updatedProposalBase
        }
      });
    }
  };

  const handleInvoiceBaseDelete = async () => {
    if (invoiceBase) {
      await deleteImage(invoiceBase);
      setInvoiceBase(null);
      await saveSettings({
        company: {
          ...companyInfo,
          invoiceBase: null
        }
      });
    }
  };

  const saveAllSettings = async () => {
    setSaving(true);
    setError(null);

    try {
      const companySettings: CompanySettings = {
        ...companyInfo,
        basicLogo,
        coverBackground,
        proposalBase,
        invoiceBase,
      };

      await saveSettings({
        company: companySettings,
        personal: personalInfo,
        preferences,
        prices,
      });
    } catch (err) {
      setError('Failed to save settings');
      console.error('Error saving settings:', err);
    } finally {
      setSaving(false);
    }
  };

  return {
    loading,
    saving,
    error,
    companyInfo,
    personalInfo,
    preferences,
    basicLogo,
    coverBackground,
    proposalBase,
    invoiceBase,
    prices,
    handleCompanyInfoChange,
    handlePersonalInfoChange,
    handlePreferencesChange,
    handlePriceChange,
    handleBasicLogoUpload,
    handleCoverBackgroundUpload,
    handleProposalBaseUpload,
    handleInvoiceBaseUpload,
    handleBasicLogoDelete,
    handleCoverBackgroundDelete,
    handleProposalBaseDelete,
    handleInvoiceBaseDelete,
    saveAllSettings,
  };
};
