import React from 'react';
import { 
  TextField, 
  TextFieldProps, 
  useTheme, 
  useMediaQuery 
} from '@mui/material';
import FormError from './FormError';

interface FormTextFieldProps extends Omit<TextFieldProps, 'error'> {
  error?: string;
}

const FormTextField: React.FC<FormTextFieldProps> = ({ 
  error, 
  helperText,
  ...props 
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <TextField
        {...props}
        error={Boolean(error)}
        helperText={helperText}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: isMobile ? '0.875rem' : '1rem',
            padding: isMobile ? '12px 14px' : '16.5px 14px',
          },
          '& .MuiInputLabel-root': {
            fontSize: isMobile ? '0.875rem' : '1rem',
          },
          '& .MuiFormHelperText-root': {
            fontSize: isMobile ? '0.7rem' : '0.75rem',
          },
          ...props.sx
        }}
      />
      {error && <FormError message={error} />}
    </>
  );
};

export default FormTextField;
