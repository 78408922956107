import { Grid, Typography } from '@mui/material';
import FormTextField from '../common/FormTextField';
import type { PersonalInfo } from './types';

interface PersonalInfoFormProps {
  personalInfo: PersonalInfo;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PersonalInfoForm = ({ personalInfo, onChange }: PersonalInfoFormProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Personal Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="First Name"
            name="firstName"
            value={personalInfo.firstName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Last Name"
            name="lastName"
            value={personalInfo.lastName}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Email"
            name="email"
            type="email"
            value={personalInfo.email}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            label="Phone"
            name="phone"
            value={personalInfo.phone}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            label="Title/Position"
            name="title"
            value={personalInfo.title}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInfoForm;
