import { Box, Typography, Button, Avatar } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface CoverBackgroundUploadProps {
  coverBackground: string | null;
  onCoverBackgroundUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CoverBackgroundUpload = ({ coverBackground, onCoverBackgroundUpload }: CoverBackgroundUploadProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Cover Background
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        {coverBackground && (
          <Avatar
            src={coverBackground}
            sx={{ width: 100, height: 100 }}
            variant="rounded"
          />
        )}
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          Upload Background
          <input
            type="file"
            hidden
            accept="application/pdf,image/*"
            onChange={onCoverBackgroundUpload}
          />
        </Button>
      </Box>
      <Typography variant="body2" color="text.secondary">
        Accepts PDF or image files. For PDFs, each page will be used as a background for the corresponding proposal page.
      </Typography>
    </>
  );
};

export default CoverBackgroundUpload;
