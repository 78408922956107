import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Invoice } from './types';
import { formatCurrency } from '../../utils/numberFormat';

interface InvoicesTableProps {
  invoices: Invoice[];
  onPreview: (invoice: Invoice) => void;
  onGeneratePDF: (invoice: Invoice) => void;
  onDelete: (invoice: Invoice) => void;
  formatDate: (date: string) => string;
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({
  invoices,
  onPreview,
  onGeneratePDF,
  onDelete,
  formatDate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ActionButtons = ({ invoice }: { invoice: Invoice }) => (
    <Stack 
      direction="row" 
      spacing={1} 
      sx={{ 
        flexWrap: isMobile ? 'wrap' : 'nowrap',
        justifyContent: 'center',
        gap: 1
      }}
    >
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        onClick={() => onPreview(invoice)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Preview
      </Button>
      <Button
        variant="contained"
        size={isMobile ? "medium" : "small"}
        onClick={() => onGeneratePDF(invoice)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Download PDF
      </Button>
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        color="primary"
        href={`/invoice/${invoice.id}`}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Edit
      </Button>
      <Button
        variant="outlined"
        size={isMobile ? "medium" : "small"}
        color="error"
        onClick={() => onDelete(invoice)}
        fullWidth={isMobile}
        sx={{ minHeight: isMobile ? '48px' : '36px' }}
      >
        Delete
      </Button>
    </Stack>
  );

  if (isMobile) {
    return (
      <Stack spacing={2} sx={{ mt: 2, px: 2 }}>
        {invoices.length === 0 ? (
          <Typography align="center" color="textSecondary">
            No invoices found
          </Typography>
        ) : (
          invoices.map((invoice) => (
            <Card key={invoice.id} elevation={2}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Invoice #:</strong> {invoice.invoiceNumber}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Client:</strong> {invoice.clientName}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Date:</strong> {formatDate(invoice.date)}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Due Date:</strong> {formatDate(invoice.dueDate)}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <strong>Amount:</strong> ${formatCurrency(invoice.amount)}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <ActionButtons invoice={invoice} />
                </Box>
              </CardContent>
            </Card>
          ))
        )}
      </Stack>
    );
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Client Name</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No invoices found
              </TableCell>
            </TableRow>
          ) : (
            invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.clientName}</TableCell>
                <TableCell>{formatDate(invoice.date)}</TableCell>
                <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                <TableCell>${formatCurrency(invoice.amount)}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <ActionButtons invoice={invoice} />
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoicesTable;
