import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { Invoice } from '../../../components/invoices/types';
import InvoiceDocument from '../components/InvoiceDocument';
import { fetchSettings } from '../../firestore';

export const generateInvoicePdfBlob = async (invoice: Invoice): Promise<Blob> => {
  try {
    // Fetch settings for the invoice base
    const settings = await fetchSettings();
    const invoiceBase = settings?.company?.invoiceBase || undefined;

    // Generate PDF document
    const document = React.createElement(InvoiceDocument, {
      invoice,
      invoiceBase
    });

    // Convert to blob
    const blob = await pdf(document).toBlob();
    return blob;
  } catch (error) {
    console.error('Error generating invoice PDF:', error);
    throw error;
  }
};

export const downloadInvoicePdf = async (blob: Blob, invoiceNumber: string) => {
  try {
    // Create download link
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `Invoice_${invoiceNumber}.pdf`;
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    
    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading invoice PDF:', error);
    throw error;
  }
};
