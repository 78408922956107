import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Proposal } from '../../../components/proposals/types';
import { generateProposalPdfBlob } from '../utils/proposalPdfUtils';

interface Props {
  proposal: Proposal & {
    levelOneSelected?: boolean;
    levelTwoSelected?: boolean;
    levelThreeSelected?: boolean;
    levelFourSelected?: boolean;
    threeYear133Selected?: boolean;
    threeYear233Selected?: boolean;
  };
  backgroundImages?: string[];
}

const FormEnabledPDF: React.FC<Props> = ({ proposal, backgroundImages = [] }) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let currentUrl: string | null = null;

    const generatePDF = async () => {
      try {
        setIsLoading(true);
        // Generate the PDF with form fields
        const pdfBlob = await generateProposalPdfBlob(proposal, backgroundImages);
        // Create a URL for the blob
        currentUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(currentUrl);
      } catch (err) {
        console.error('Error generating PDF:', err);
        setError('Failed to generate PDF preview');
      } finally {
        setIsLoading(false);
      }
    };

    generatePDF();

    // Cleanup
    return () => {
      if (currentUrl) {
        URL.revokeObjectURL(currentUrl);
      }
    };
  }, [proposal, backgroundImages]); // removed pdfUrl dependency as we handle it with currentUrl

  if (isLoading) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%' 
      }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%',
        color: 'red'
      }}>
        {error}
      </div>
    );
  }

  if (!pdfUrl) {
    return null;
  }

  return (
    <iframe
      title="Proposal PDF Preview"
      src={`${pdfUrl}#toolbar=0`}
      style={{
        width: '100%',
        height: '100%',
        border: 'none'
      }}
    />
  );
};

export default FormEnabledPDF;
