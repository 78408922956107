import { Button } from '@mui/material';

interface SaveButtonProps {
  onClick: () => void;
}

export const SaveButton = ({ onClick }: SaveButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    size="large"
    fullWidth
    onClick={onClick}
    sx={{ mt: 2 }}
  >
    Save Settings
  </Button>
);
