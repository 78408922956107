import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import type { DocumentProps } from '@react-pdf/types';
import { Proposal } from '../../../components/proposals/types';
import { formatCurrency } from '../../../utils/numberFormat';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  backgroundImageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  pricesContainer: {
    position: 'absolute',
    top: 210,
    left: 60,
    right: 30,
    height: 80,
  },
  priceRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  checkbox: {
    width: 10,
    height: 10,
    border: '1px solid black',
    marginRight: 10,
    marginTop: 2,
  },
  priceText: {
    fontFamily: 'Times-Roman',
    color: '#000000',
    flex: 1,
  },
  clientNameContainer: {
    position: 'absolute',
    top: 221.5,
    left: 185,
    right: 30,
  },
  clientName: {
    fontFamily: 'Times-Roman',
    color: '#000000',
    fontSize: 12,
  },
  clientNamePage4Container: {
    position: 'absolute',
    top: 291,
    left: 86,
    right: 20,
  },
  clientNamePage4: {
    fontFamily: 'Times-Roman',
    color: '#000000',
    fontSize: 12,
  },
  pageNumber: {
    position: 'absolute',
    bottom: 18,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Times-Roman',
    color: '#000000',
  }
});

interface ProposalWithSelections extends Proposal {
  levelOneSelected?: boolean;
  levelTwoSelected?: boolean;
  levelThreeSelected?: boolean;
  levelFourSelected?: boolean;
  threeYear133Selected?: boolean;
  threeYear233Selected?: boolean;
}

interface Props extends DocumentProps {
  proposal: ProposalWithSelections;
  backgroundImages?: string[];
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

const getServiceDescription = (name: string) => {
  switch (name) {
    case 'Level One':
      return 'Level 1: Create an initial report from site walk and governing documents';
    case 'Level Two':
      return 'Level 2: Update an existing report with site walk';
    case 'Level Three':
      return 'Level 3: Update an existing report without site walk';
    case 'Level Four':
      return 'Level 4: Create an initial report from building plans';
    case '3 Year 2/3/3':
      return '3 Year Contract. Package includes: 1 Level 2 report + 2 Level 3 reports (10% discount applied). Flat amount to be billed annually';
    case '3 Year 1/3/3':
      return '3 Year Contract. Package includes: 1 Level 1 report + 2 Level 3 reports (10% discount applied). Flat amount to be billed annually';
    default:
      return name;
  }
};

const ProposalDocument: React.FC<Props> = ({ proposal, backgroundImages = [] }) => {
  const selectedPrices = [
    { name: 'Level One', cost: proposal.levelOneCost, selected: proposal.levelOneSelected },
    { name: 'Level Two', cost: proposal.levelTwoCost, selected: proposal.levelTwoSelected },
    { name: 'Level Three', cost: proposal.levelThreeCost, selected: proposal.levelThreeSelected },
    { name: 'Level Four', cost: proposal.levelFourCost, selected: proposal.levelFourSelected },
    { name: '3 Year 1/3/3', cost: proposal.threeYear133Cost, selected: proposal.threeYear133Selected },
    { name: '3 Year 2/3/3', cost: proposal.threeYear233Cost, selected: proposal.threeYear233Selected },
  ].filter(price => price.selected);

  // Adjust font size and spacing based on number of items
  const fontSize = selectedPrices.length <= 3 ? 12 : 10;
  const marginBottom = selectedPrices.length <= 3 ? 8 : 4;

  return (
    <Document>
      {[1, 2, 3, 4].map((pageNumber) => (
        <Page key={pageNumber} size="A4" style={styles.page}>
          <View style={styles.backgroundImageWrapper}>
            {backgroundImages[pageNumber - 1] && (
              <Image src={backgroundImages[pageNumber - 1]} style={styles.backgroundImage} />
            )}
          </View>
          {pageNumber === 1 && (
            <View style={styles.clientNameContainer}>
              <Text style={styles.clientName}>
                {proposal.clientName},
              </Text>
            </View>
          )}
          {pageNumber === 4 && (
            <View style={styles.clientNamePage4Container}>
              <Text style={styles.clientNamePage4}>
                {proposal.clientName} - {formatDate(proposal.date)}
              </Text>
            </View>
          )}
          {pageNumber === 3 && selectedPrices.length > 0 && (
            <View style={styles.pricesContainer}>
              {selectedPrices.map((price, index) => (
                <View 
                  key={index} 
                  style={[
                    styles.priceRow,
                    { marginBottom: index < selectedPrices.length - 1 ? marginBottom : 0 }
                  ]}
                >
                  <View style={styles.checkbox} />
                  <Text style={[styles.priceText, { fontSize }]}>
                    ${formatCurrency(price.cost)} - {getServiceDescription(price.name)}
                  </Text>
                </View>
              ))}
            </View>
          )}
          <Text style={styles.pageNumber}>
            Page {pageNumber} of 4
          </Text>
        </Page>
      ))}
    </Document>
  );
};

export default ProposalDocument;
