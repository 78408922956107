import { PDFDocument, rgb } from 'pdf-lib';

interface FormField {
  name: string;
  type: 'text' | 'checkbox' | 'signature';
  page: number;
  x: number;
  y: number;
  width: number;
  height: number;
  isSignature?: boolean;
}

/**
 * Adds form fields to an existing PDF
 * @param pdfBytes The original PDF as a Uint8Array
 * @returns A Promise resolving to the modified PDF as a Uint8Array
 */
export async function addSignatureField(pdfBytes: Uint8Array): Promise<Uint8Array> {
  try {
    // Load the existing PDF
    const pdfDoc = await PDFDocument.load(pdfBytes, { 
      updateMetadata: false,
      ignoreEncryption: true
    });
    
    // Get the last page (where we'll add the signature)
    const pages = pdfDoc.getPages();
    const lastPage = pages[pages.length - 1];
    
    // Get page dimensions for y-coordinate calculation
    const { height } = lastPage.getSize();

    // Define all form fields
    const fields: FormField[] = [
      // Signature field for page 4
      {
        name: 'signature',
        type: 'signature',
        page: pages.length - 1,
        x: 102,
        y: height - 332,
        width: 170,
        height: 24,
        isSignature: true
      },
      {
        name: 'Printname',
        type: 'text',
        page: pages.length - 1,
        x: 110,
        y: height - 357,
        width: 200,
        height: 20,
      },
      {
        name: 'title',
        type: 'text',
        page: pages.length - 1,
        x: 90,
        y: height - 376,
        width: 170,
        height: 15,
      },
      {
        name: 'pointOfContact',
        type: 'text',
        page: pages.length - 1,
        x: 150,
        y: height - 432,
        width: 100,
        height: 20,
      },
      // Service option checkboxes on page 3
      {
        name: 'level1',
        type: 'checkbox',
        page: 2,
        x: 58,
        y: height - 225,
        width: 12,
        height: 12,
      },
      {
        name: 'level2',
        type: 'checkbox',
        page: 2,
        x: 58,
        y: height - 245,
        width: 12,
        height: 12,
      },
      {
        name: 'level3',
        type: 'checkbox',
        page: 2,
        x: 58,
        y: height - 265,
        width: 12,
        height: 12,
      }
    ];

    // Create form
    const form = pdfDoc.getForm();

    // Add all fields
    fields.forEach(field => {
      const page = pdfDoc.getPage(field.page);
      
      if (field.type === 'signature') {
        // Create a signature field using a standard text field with specific properties
        const signatureField = form.createTextField(field.name);
        signatureField.addToPage(page, {
          x: field.x,
          y: field.y,
          width: field.width,
          height: field.height,
          borderWidth: 1,
          backgroundColor: rgb(0.98, 0.98, 0.98),
        });

        // Set field properties for digital signature
        const acroField = signatureField.acroField;
        const context = pdfDoc.context;
        
        // Set the field type to signature
        acroField.dict.set(context.obj('FT'), context.obj('Sig'));
        
        // Set signature-specific flags
        acroField.setFlagTo(1 << 13, true); // Signature flag
        
        // Remove any default text
        signatureField.setText('');
      } else if (field.type === 'text') {
        const textField = form.createTextField(field.name);
        textField.addToPage(page, {
          x: field.x,
          y: field.y,
          width: field.width,
          height: field.height,
          borderWidth: 1,
          backgroundColor: rgb(0.95, 0.95, 0.95),
        });
      } else if (field.type === 'checkbox') {
        const checkBox = form.createCheckBox(field.name);
        checkBox.addToPage(page, {
          x: field.x,
          y: field.y,
          width: field.width,
          height: field.height,
          borderWidth: 1,
        });
      }
    });
    
    // Save the modified PDF with specific options to maintain signature field
    const pdfBytes2 = await pdfDoc.save({
      useObjectStreams: false,
      addDefaultPage: false,
      updateFieldAppearances: false
    });
    
    return pdfBytes2;
  } catch (error) {
    console.error('Error adding form fields:', error);
    // If anything goes wrong, return the original PDF unchanged
    return pdfBytes;
  }
}
