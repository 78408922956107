import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  InputAdornment,
} from '@mui/material';
import FormTextField from '../common/FormTextField';
import { PriceSettings, PriceLevelKey } from '../../services/types';
import { formatCurrency, parseCurrencyInput } from '../../utils/numberFormat';

interface PriceSettingsSectionProps {
  prices: PriceSettings;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

type PropertyType = 'SFH' | 'TH' | 'Condo';

interface RegionData {
  title: string;
  types: PropertyType[];
}

const PriceSettingsSection: React.FC<PriceSettingsSectionProps> = ({ prices = {}, onChange }) => {
  const regions: RegionData[] = [
    {
      title: 'WA with <100 doors',
      types: ['SFH', 'TH', 'Condo'],
    },
    {
      title: 'WA with >100 doors',
      types: ['SFH', 'TH', 'Condo'],
    },
    {
      title: 'OR with <100 doors',
      types: ['SFH', 'TH', 'Condo'],
    },
    {
      title: 'OR with >100 doors',
      types: ['SFH', 'TH', 'Condo'],
    }
  ];

  const columns: PriceLevelKey[] = [
    'Level 1',
    'Level 2',
    'Level 3',
    'Level 4',
    '3 year (1+3+3)',
    '3 year (2+3+3)'
  ];

  const handleChange = (region: string, type: PropertyType, level: PriceLevelKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseCurrencyInput(e.target.value);
    const value = parsedValue === '' ? '0' : parsedValue;
    
    if (onChange) {
      const newEvent = {
        ...e,
        target: {
          ...e.target,
          name: `${region}|${type}|${level}`,
          value
        }
      };
      onChange(newEvent);
    }
  };

  const getDisplayValue = (region: string, type: PropertyType, column: PriceLevelKey): string => {
    const value = prices[region]?.[type]?.[column] || '0';
    return value;
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Price Settings
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 200, fontWeight: 'bold' }}>Region</TableCell>
              {columns.map((column) => (
                <TableCell key={column} align="center" style={{ minWidth: 120, fontWeight: 'bold' }}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((region) => (
              <React.Fragment key={region.title}>
                {/* Region Title Row */}
                <TableRow>
                  <TableCell
                    colSpan={columns.length + 1}
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                  >
                    {region.title}
                  </TableCell>
                </TableRow>
                {/* Property Type Rows */}
                {region.types.map((type) => (
                  <TableRow key={`${region.title}-${type}`}>
                    <TableCell style={{ paddingLeft: '2rem' }}>
                      {type}
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell key={`${region.title}-${type}-${column}`} align="center">
                        <FormTextField
                          value={getDisplayValue(region.title, type, column)}
                          onChange={handleChange(region.title, type, column)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          sx={{ width: '120px' }}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PriceSettingsSection;
