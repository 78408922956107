import React, { Suspense } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { Invoice } from './types';

// Lazy load the InvoicePreview component
const InvoicePreview = React.lazy(() => import('./InvoicePreview'));

interface InvoicePreviewDialogProps {
  invoice: Invoice | null;
  onClose: () => void;
}

const InvoicePreviewDialog: React.FC<InvoicePreviewDialogProps> = ({ invoice, onClose }) => {
  return (
    <Dialog
      open={!!invoice}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent style={{ height: '90vh', padding: 0 }}>
        {invoice && (
          <Suspense fallback={<LoadingSpinner />}>
            <InvoicePreview invoice={invoice} />
          </Suspense>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default InvoicePreviewDialog;
