import React from 'react';
import { Grid, Typography, Switch, FormControlLabel } from '@mui/material';
import { SettingsSection } from './SettingsLayout';

interface PreferencesSectionProps {
  preferences: {
    includeCompanyLogo: boolean;
    includeCoverBackground: boolean;
    darkMode: boolean;
    compactView: boolean;
  };
  onPreferenceChange: (name: string, value: boolean) => void;
}

const PreferencesSection: React.FC<PreferencesSectionProps> = ({
  preferences,
  onPreferenceChange,
}) => {
  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    onPreferenceChange(name, event.target.checked);
  };

  return (
    <SettingsSection>
      <Typography variant="h6" gutterBottom>
        Preferences
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.includeCompanyLogo}
                onChange={handleChange('includeCompanyLogo')}
              />
            }
            label="Include Company Logo"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.includeCoverBackground}
                onChange={handleChange('includeCoverBackground')}
              />
            }
            label="Include Cover Background"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.darkMode}
                onChange={handleChange('darkMode')}
              />
            }
            label="Dark Mode"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={preferences.compactView}
                onChange={handleChange('compactView')}
              />
            }
            label="Compact View"
          />
        </Grid>
      </Grid>
    </SettingsSection>
  );
};

export default PreferencesSection;
