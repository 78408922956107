import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface BasicLogoUploadProps {
  basicLogo: string | null;
  onBasicLogoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const BasicLogoUpload = ({ basicLogo, onBasicLogoUpload }: BasicLogoUploadProps) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Basic Logo
      </Typography>
      <Typography variant="body2" color="textSecondary" gutterBottom>
        Upload a basic version of your logo for use in the cover page
      </Typography>
      
      <Box sx={{ mt: 2, mb: 2 }}>
        {basicLogo && (
          <Box sx={{ mb: 2 }}>
            <img 
              src={basicLogo} 
              alt="Basic Logo Preview" 
              style={{ 
                maxWidth: '200px', 
                maxHeight: '100px', 
                objectFit: 'contain' 
              }} 
            />
          </Box>
        )}
        
        <Button
          variant="outlined"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          {basicLogo ? 'Change Basic Logo' : 'Upload Basic Logo'}
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={onBasicLogoUpload}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default BasicLogoUpload;
