import React from 'react';
import BrandingSection from './settings/BrandingSection';
import PersonalInfoForm from './settings/PersonalInfoForm';
import CompanyInfoForm from './settings/CompanyInfoForm';
import PriceSettingsSection from './settings/PriceSettingsSection';
import PreferencesSection from './settings/PreferencesSection';
import { useSettingsForm } from './settings/useSettingsForm';
import { useSettingsSnackbar } from './settings/hooks/useSettingsSnackbar';
import { SettingsLayout } from './settings/SettingsLayout';
import { SettingsSection } from './settings/SettingsLayout';
import { SettingsSnackbar } from './settings/SettingsSnackbar';
import { SaveButton } from './settings/SaveButton';
import { PriceLevelKey } from '../services/types';

const Settings = () => {
  const {
    basicLogo,
    coverBackground,
    proposalBase,
    invoiceBase,
    personalInfo,
    companyInfo,
    preferences,
    prices,
    handleBasicLogoUpload,
    handleCoverBackgroundUpload,
    handleProposalBaseUpload,
    handleProposalBaseDelete,
    handleInvoiceBaseUpload,
    handleInvoiceBaseDelete,
    handlePersonalInfoChange,
    handleCompanyInfoChange,
    handlePreferencesChange,
    handlePriceChange,
    saveAllSettings
  } = useSettingsForm();

  const {
    snackbar,
    showSuccessSnackbar,
    showErrorSnackbar,
    handleCloseSnackbar
  } = useSettingsSnackbar();

  const handleSave = async () => {
    try {
      await saveAllSettings();
      showSuccessSnackbar('Settings saved successfully');
    } catch (error) {
      console.error('Error saving settings:', error);
      showErrorSnackbar('Error saving settings');
    }
  };

  const handlePriceChangeWrapper = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const [region, propertyType, level] = name.split('.');
    handlePriceChange(region, propertyType, level as PriceLevelKey, value);
  };

  const handleProposalBaseUploadWrapper = (pageIndex: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      const uploadHandler = await handleProposalBaseUpload(pageIndex);
      await uploadHandler(event);
    }
  };

  return (
    <SettingsLayout>
      <SettingsSection>
        <BrandingSection
          basicLogo={basicLogo}
          coverBackground={coverBackground}
          proposalBase={proposalBase}
          invoiceBase={invoiceBase}
          onBasicLogoUpload={handleBasicLogoUpload}
          onCoverBackgroundUpload={handleCoverBackgroundUpload}
          onProposalBaseUpload={handleProposalBaseUploadWrapper}
          onProposalBaseDelete={handleProposalBaseDelete}
          onInvoiceBaseUpload={handleInvoiceBaseUpload}
          onInvoiceBaseDelete={handleInvoiceBaseDelete}
        />
      </SettingsSection>

      <SettingsSection>
        <PersonalInfoForm
          personalInfo={personalInfo}
          onChange={handlePersonalInfoChange}
        />
      </SettingsSection>

      <SettingsSection>
        <CompanyInfoForm
          companyInfo={companyInfo}
          onChange={handleCompanyInfoChange}
        />
      </SettingsSection>

      <SettingsSection>
        <PreferencesSection
          preferences={preferences}
          onPreferenceChange={handlePreferencesChange}
        />
      </SettingsSection>

      <SettingsSection isLast>
        <PriceSettingsSection
          prices={prices}
          onChange={handlePriceChangeWrapper}
        />
      </SettingsSection>

      <SaveButton onClick={handleSave} />

      <SettingsSnackbar
        snackbar={snackbar}
        onClose={handleCloseSnackbar}
      />
    </SettingsLayout>
  );
};

export default Settings;
