import React, { ReactNode } from 'react';
import { 
  FormControl, 
  InputLabel, 
  Select, 
  SelectChangeEvent,
  useTheme,
  useMediaQuery
} from '@mui/material';
import FormError from './FormError';

interface FormSelectProps {
  label: string;
  error?: string;
  children: ReactNode;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  fullWidth?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  label,
  error,
  children,
  value,
  onChange,
  fullWidth = false,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <FormControl 
      fullWidth={fullWidth} 
      error={Boolean(error)}
      sx={{
        '& .MuiInputBase-input': {
          fontSize: isMobile ? '0.875rem' : '1rem',
          padding: isMobile ? '12px 14px' : '16.5px 14px',
        },
        '& .MuiInputLabel-root': {
          fontSize: isMobile ? '0.875rem' : '1rem',
        }
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        value={value}
        onChange={onChange}
        {...props}
      >
        {children}
      </Select>
      {error && <FormError message={error} />}
    </FormControl>
  );
};

export default FormSelect;
